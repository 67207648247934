.layout {
    color: var(--txt-color);
}

.layout__content {
    padding-left: var(--sidebar-width);
    background-color: var(--second-bg);
    min-height: 100vh;
}

.layout__content-main {
    padding: 30px;
}

.user_logged{
    float: right;
    text-shadow: 1px 1px;
    text-transform: lowercase;
}

.center_text_api {
    text-align: center;
}

.error { border: 1px solid red; }

.api_key_all_lower{
    color: green;
    text-transform: lowercase;;
}
.api_domain_all_lower{
    text-transform: lowercase;;
}

.not_active_status{
    color: red;
}

.active_status{
    color: green;
}
